@import "animate.css";
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap");
body {
  background-color: #fff !important;
  overflow-x: hidden !important;
  overflow-y: auto;
}

* {
  font-family: "Work Sans", sans-serif !important;
}

.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: block;
        border-radius: 100%;
        border: 1px solid darken(black, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: red;
          box-shadow: inset 0 0 0 4px red;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: red;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px black;
          border-color: darken(black, 25%);
          background: darken(black, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
.container-fluid {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

h1 {
  // text-transform: uppercase;
  font-size: 50px;
  font-weight: lighter;
  color: #131313;
  margin-bottom: 0;
}

.section-content {
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    width: 55%;
    color: #000000;
  }
  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #131313;
    &:hover {
      text-decoration: none;
    }
    img {
      object-fit: contain;
    }
  }
}
.object-fit {
  object-fit: contain;
}
.main-arrow {
  width: 92px;
  height: 92px;
  object-fit: contain;
}

.main-products {
  margin-top: 7rem;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #131313;
  }
  p {
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    color: #ffffff;
    position: absolute;
    top: 35%;
    // left: 25%;
    text-align: center;
    width: 100%;
  }
  a {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;

    color: #131313;
    &:hover {
      text-decoration: none;
    }
  }
  .branch-content {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #131313;
  }
}

.brands {
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.14em;
    color: #131313;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #000000;
  }

  img {
    width: 130px !important;
    height: 100px !important;
  }
  button {
    display: none !important;
  }
}
.slick-slide div {
  text-align: center !important;
}

.main {
  min-height: 100%;
  margin-top: 150px;
  width: 100%;
  overflow: hidden;
}

.marquee-container {
  height: 60px;
  overflow: hidden;
  line-height: 30px;
  font-size: 30px;
  // font-weight: 600;
  // text-transform: uppercase;
  margin: 30px 0 0 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;

  .marquee {
    top: 0;
    left: 100%;
    width: 100%;
    // overflow: hidden;
    padding: 15px 0;
    position: absolute;
    white-space: nowrap;
    animation: marquee 25s linear infinite;

    span {
      margin-right: 20px;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.marquee-container-about {
  height: 110px;
  overflow: hidden;
  line-height: 30px;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 30px 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;

  .marquee-about {
    top: 0;
    left: 100%;
    width: 100%;
    // overflow: hidden;
    // padding: 20px 0;
    position: absolute;
    white-space: nowrap;
    animation: marquee 30s linear infinite;

    img {
      height: 73px;
      object-fit: cover;
      padding-bottom: 15px;
      margin: 0 30px;
      padding-top: 0 !important;
    }
  }
}

@keyframes marquee {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.choose-us {
  background-color: #131313;
  padding: 7rem 0;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    color: #f8f8ef;
  }
  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #f8f8ef;
    margin-top: 20px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    color: #f8f8ef;
  }
}

.catalog {
  margin: 6rem 0 3rem 0;
  color: #131313;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
  button {
    background-color: #000000;
    border-radius: 67px;
    padding: 10px 25px;
    font-weight: 500;
    font-size: 16px;
    // line-height: 5px;
    color: #f8f8ef;
    a {
      color: #f8f8ef;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      transform: scale(1.1);
      transition: 0.3s all;
    }
  }
  .catalog-button {
    padding-right: 4rem;
  }
  img {
    padding: 40px 40px;
  }
}

.production {
  color: #000000;
  font-weight: 400;
  h2 {
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 2px;
  }
  p {
    font-size: 16px;
    line-height: 150%;
    width: 54%;
  }
}
.statics {
  color: #131313;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #131313;
  }
  p {
    font-weight: 600;
    font-size: 130px;
    line-height: 152px;
  }
  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
  .meats {
    img {
      height: 500px;
    }
    address {
      font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    }
  }
}
.private-label {
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #131313;
  }
  .private-label-cover {
    background-color: #d5d5d5;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-transform: capitalize;
    color: #000000;
    width: 44%;
  }
}
.flour {
  margin-bottom: 5rem;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #131313;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #131313;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 136.5%;
    color: #000000;
  }
}

.favelli {
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #131313;
  }
  img {
    padding: 80px 0;
    height: 600px;
    object-fit: contain;
    &:hover {
      transform: scale(1.1);
      transition: 0.3s all;
    }
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #131313;
    text-transform: uppercase;
  }
}

.meats-main {
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 130%;
    /* or 39px */

    color: #000000;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    color: #000000;
  }
}

.rublevski {
  align-items: center;
  .rublevski-logo {
    padding-right: 6rem;
  }
  .rublevski-content {
    padding-right: 19rem;
    span {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 140% !important;
      /* or 22px */

      color: #f8f8ef !important;
    }
    button {
      background-color: white;
      border: none;
      border-radius: 67px;
      padding: 10px 25px;

      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      color: #131313;
      a {
        color: #131313;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.export {
  h2 {
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-right: 16rem;
  }
  button {
    background: #131313;
    border-radius: 67px;
    color: white;
    border: none;
    padding: 10px 60px;
  }
  color: #131313;
  input,
  textarea {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    font-size: 14px;
    &:focus {
      border: none;
      border-bottom: 1px solid black;
    }
  }
}

.langs-element {
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  position: absolute;
  right: 150px;
  top: 35px;

  label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    /* Accessible outline */
    /* Remove comment to use */
    /*
      &:focus-within {
          outline: .125em solid $primary-color;
      }
    */
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        // background-color: mix(#fff, black, 84%);
        &:before {
          box-shadow: inset 0 0 0 0.4375em black;
          
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      padding: 0.375em 0.75em 0.375em 0.375em;
      border-radius: 99em; // or something higher...
      transition: 0.25s ease;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      /* identical to box height */

      color: #131313;
      &:hover {
        // background-color: mix(#fff, black, 84%);
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        background-color: #fff !important;
        box-shadow: inset 0 0 0 0.125em black;
      }
    }
  }
}

// button {
//   background-color: transparent;
//   border: 1px solid #131313;
//   padding: 0 25px;
//   height: 40px;

//   a {
//     color: black;
//     font-weight: 500;
//     font-size: 16px;
//     text-decoration: none;
//   }

//   &:hover {
//     background-color: black;
//     transition: all 0.7s;

//     a {
//       color: white;
//       text-decoration: none;
//     }
//   }
// }

.about-cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.statistics {
  p {
    font-size: 150px;
    font-weight: 400;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #a9a9a9;
    text-transform: uppercase;
  }
}

.why-choose-us {
  margin-right: 65rem;
}

.products {
  background-color: #131313;
  margin-top: 8rem;

  h1,
  p {
    color: white;
  }

  button {
    background-color: transparent;
    border: 1px solid white;
    padding: 5px 25px;

    a {
      color: white;
      font-weight: 500;
      font-size: 16px;
      text-decoration: none;
    }

    &:hover {
      background-color: black;
      transition: all 0.7s;

      a {
        color: white;
        text-decoration: none;
      }
    }
  }

  .product-img {
    img {
      background-color: #f0f0f0;
      // padding: 0 6rem;
      margin: 5px 0;
    }

    .product-name {
      padding: 10px 0;
      width: 100%;
    }
  }

  .product3 {
    margin-right: 9rem;
    margin-bottom: 6rem;
  }

  .product-desc {
    padding-right: 15rem;
  }
}

.product-text {
  margin-left: 60rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #131313;
}

.product-text-left {
  margin-right: 60rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #131313;
}

.projects {
  margin-bottom: 80px;

  img {
    height: 565px;
  }

  .project-desc {
    background-color: white;
    color: #131313;
  }

  h3 {
    font-size: 23px;
    font-weight: 400;
    color: #000000;
  }

  span {
    color: #a9a9a9;
    font-size: 14px;
  }

  p {
    font-size: 14px;
    color: #1b1b1b;
  }

  a:hover {
    text-decoration: none;
  }
}

.about-header {
  margin-left: 35rem;
  color: #131313;
  font-weight: 500;
  font-size: 50px;
  line-height: 115%;
}

.sustain-header {
  margin-left: 30rem;
  color: #131313;
  font-weight: 500;
  font-size: 50px;
  line-height: 115%;
  margin-top: 8rem;
  text-align: end;
}

.about-sustain {
  margin-right: 35rem;
  color: #131313;
  font-weight: 500;
  font-size: 50px;
  line-height: 115%;
}

.sustain-desc p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #131313;
}

.about {
  width: 50%;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #131313;
  margin: 5rem 0;
}

.company-value {
  color: #131313;

  p {
    font-weight: 500;
    font-size: 30px;
    line-height: 150%;
    text-transform: uppercase;
  }

  h2 {
    font-weight: 400;
    font-size: 150px;
    line-height: 115%;
    text-transform: uppercase;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
}

.vision {
  padding-right: 15rem;

  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 115%;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
}

.vision-img {
  height: 400px;
  margin-bottom: 6rem;
}

.contact {
  .inputs {
    display: flex;
    flex-direction: column;
  }
  input,
  textarea {
    outline: none;
    border: 1px solid #131313;
    border-radius: 5px;
    width: 70%;
    font-size: 14px;
    background: #fff;
  }
  button {
    background: #131313;
    border-radius: 67px;
    color: white;
    padding: 10px 50px;
  }
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #131313;
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #131313;
    width: 140px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #131313;
    &:first-child {
      margin-left: 120px;
    }
  }

  // .contact-info {
  //   padding-right: 5rem;

  //   span {
  //     font-weight: 400;
  //     font-size: 14px;
  //     line-height: 140%;
  //     color: #a9a9a9;
  //   }

  //   address {
  //     font-weight: 500;
  //     font-size: 16px;
  //     line-height: 21px;
  //     color: #131313;
  //   }
  // }

  // .contact-info-number {
  //   padding-right: 7.5rem;

  //   span {
  //     font-weight: 400;
  //     font-size: 14px;
  //     line-height: 140%;
  //     color: #a9a9a9;
  //   }

  //   address {
  //     font-weight: 500;
  //     font-size: 16px;
  //     line-height: 21px;
  //     color: #131313;
  //   }
  // }
}

.border-1 {
  border: 1px solid black;
}

.cursor {
  cursor: pointer;
}

.border-t {
  border-top: 1px solid black;
}

.border-b {
  border-bottom: 1.5px solid black;
}

.border-l {
  border-left: 1px solid black;
}

.border-r {
  border-right: 1px solid black;
}

// .header {
//   position: fixed;
//   transition: .3s all;
//   background: white;
//   top: 0;
//   left: 0;
//   right: 0;
//   padding: 20px 40px;
//   z-index: 99;

//   &.white {
//     nav {
//       .nav-menu {
//         a {
//           color: white;
//         }
//       }
//     }
//   }

//   nav {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;

//     .nav-menu {
//       gap: 60px;

//       a {
//         font-size: 15px;
//         color: #131313;
//         padding: 0 18px;
//         text-transform: uppercase;
//         font-weight: 400;

//         &:focus,
//         &:hover {
//           background-color: transparent !important;
//           text-decoration: none;
//         }

//         span {
//           position: relative;
//           width: 100%;
//         }
//       }
//     }

//     .logo {
//       width: auto;

//       img {
//         height: 100%;
//       }
//     }

//     .lang {
//       .customselect {
//         position: relative;

//         p {
//           color: black;
//           text-transform: uppercase;
//         }

//         .choose {
//           position: absolute;
//           top: 20px;
//         }
//       }
//     }

//   }

//   &.active {
//     box-shadow: 0 0 30px 0 rgba(0, 0, 0, .12);
//     padding-top: 10px;

//     //padding-bottom: 30px;
//     //position: fixed;
//     nav {
//       .logo {
//         transform: translateY(2px);
//       }
//     }
//   }

//   &.white.active {
//     nav {
//       .nav-menu {
//         a {
//           color: black;
//         }
//       }
//     }
//   }
// }
.color-black {
  color: #000000 !important;
}

.header {
  position: fixed;
  transition: 0.3s all;
  background:#fff ;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 40px;
  z-index: 99;

  .nav-links {
    display: flex;
    flex-direction: column;
    background: #fff;

    img {
      object-fit: contain;
      width: 35px;
      height: 35px;
      display: none;
      
    }
  }

  &.active {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
    padding-top: 20px;

    //padding-bottom: 30px;
    //position: fixed;
    nav {
      .logo {
        transform: translateY(2px);
      }
    }
  }

  &.white.active {
    nav {
      .nav-menu {
        a {
          color: black;
        }
      }
    }
  }

  div {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff ;
  }

  ul {
    list-style: none;
  }

  li {
    a {
      display: block;
      padding: 10px;
      text-decoration: none;
      font-weight: 400;
      font-size: 60px;
      // line-height: 75px;
      color: #131313;
      // text-transform: uppercase;
      &:hover {
        img {
          display: block;
        }
      }
    }
  }

  .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }
  .lang-link {
    position: absolute;
    right: 150px;
    top: 35px;
  }

  .lang-link li {
    color: #cfcfcf;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;

    &:hover,
    ::selection {
      color: #131313;
    }
  }

  .langs {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
  }

  .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;

    .navicon {
      background: #fff;
      display: block;
      height: 2px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 18px;

      &:after {
        background: #333;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 41px;
        top: -5px;
      }

      &:before {
        background: #333;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 41px;
        top: 3px;
      }
    }
  }

  .menu-btn {
    display: none;

    &:checked ~ .menu {
      max-height: 100vh;
    }

    &:checked ~ .menu-icon {
      &:not(.steps) {
        .navicon {
          &::after,
          &::before {
            top: 0;
          }
        }
      }

      .navicon {
        background: transparent;

        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .header li {
    float: left;
  }

  .header li a {
    padding: 10px;
  }

  // .header .menu {
  //   clear: none;
  //   float: right;
  //   max-height: none;
  // }

  // .header .menu-icon {
  //   display: none;
  // }
}

.accordion-button {
  width: 100%;
  border: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;

  img {
    width: 70px;
    height: 70px;
  }

  &:hover {
    background-color: transparent;
  }
}

.accordion-item {
  // border-top: 1px solid #000000;
  // border-bottom: 1px solid #000000;
  padding: 40px 0;
}

.accordion-header {
  position: relative;
  margin-bottom: 0;
  color: #000000;

  p {
    margin-left: 5.5rem;
    font-weight: 600;
    font-size: 25px;
    line-height: 140%;
    text-transform: uppercase;
  }
}

.accordion-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.accordion-collapse {
  margin-top: 20px;
  margin-right: 55rem;
  margin-left: 10rem;
}

// .accordion-button:after {
//   flex-shrink: 0;
//   width: 1.25rem;
//   height: 1.25rem;
//   margin-left: auto;
//   content: "";
//   background-image: url(../public/static/arrow-collapse.svg);
//   background-repeat: no-repeat;
//   background-size: 1.25rem;
//   transition: transform .5s ease-in-out;
//   position: absolute;
//   right: 0;
//   top: 10px;
// }

// .accordion-button:not(.collapsed):after {
//   background-image: url(../public/static/arrow-collapse.svg);
//   -webkit-transform: rotate(-180deg);
//   transform: rotate(-180deg);
//   transition: transform .3s ease-in-out;
// }

.project-loc {
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #a9a9a9;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #1b1b1b;
  }
}

.container-fluid {
  padding-right: 40px;
  padding-left: 40px;
}

.active {
  opacity: 1;
  visibility: visible;
  right: 0;
}

.content {
  display: flex;
  flex-direction: column;
  height: 160px;
  padding: 0;
  justify-content: space-between;
  padding: 25px 25px 5px 25px;
}

.footer {
  background-color: black;
  color: #979797;
  font-size: 11px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  margin-top: 5rem !important;
  .footer-img {
    div {
      // width: 20%;
      img {
        width: 100%;
      }
    }
  }
  .footer-border {
    border-bottom: 1px solid white;
  }

  .copy-writer p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    /* identical to box height */

    color: #f8f8ef;
  }
  h1 {
    font-weight: 600;
    font-size: 242px;
    line-height: 283px;
    text-align: center;
    color: #f8f8ef;
    letter-spacing: 12px;
  }
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #acacac;
    &:hover {
      text-decoration: none;
      color: white;
    }
    img {
      object-fit: contain;
    }
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #acacac;
  }

  .down {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
  }

  .address {
    margin-right: 5rem;
  }

  .page-links {
    margin-left: 14rem;
  }

  .contact-footer {
    margin-top: 5rem;

    p {
      font-size: 50px;
      font-weight: 600;
      line-height: 64px;
      color: #fafafa;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 10px 0;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    color: #f8f8ef;

    &:hover {
      color: white;
    }
  }

  li {
    list-style: none;
    margin-bottom: 10px;

    &:hover {
      color: white;
    }
  }
}

.accordion-collapse {
  margin-top: 20px;
  margin-right: 0;
  margin-left: 10rem;
}

@media (min-width: 1537px) {
}

@media (max-width: 1450px) {
  // .footer {
  //   width: 104% !important;
  // }
  // .main {
  //   padding: 0 40px;
  // }

  // .projects,
  // .products {
  //   padding: 0 40px;
  // }
}

@media (max-width: 1202px) {
  .why-choose-us {
    margin-right: 48rem;
  }

  .company-value-border {
    border-bottom: 1px solid black;
    // border-left: none !important;
    padding-bottom: 20px;
  }

  .value1 {
    align-items: center;
    display: flex;
  }

  .accordion-collapse {
    margin-right: 0;
  }

  .about-header {
    margin-left: 5rem;
  }

  .product-text {
    margin-left: 5rem;
  }

  .product-text-left {
    margin-right: 5rem;
  }

  .sustain-header {
    margin-left: 0;
  }

  .about-sustain {
    margin-right: 0;
  }

  .vision {
    padding-right: 0;
  }
}

@media (max-width: 1025px) {
  .section-content p {
    width: 100%;
  }
  .footer h1 {
    font-size: 178px;
  }
  .why-choose-us {
    margin-right: 0;
  }
  .private-label p {
    width: 100%;
  }
  .main-products .branch-content {
    font-size: 14px;
  }
  .main-products a {
    font-size: 14px;
  }
  .about-cover img {
    -o-object-fit: cover;
    object-fit: cover;
    // width: 105% !important;
    height: 100%;
  }
  .export p {
    margin-right: 0;
  }

  .marquee-container {
    // width: 105%;
  }

  .about {
    width: 100%;
  }

  .vision {
    padding-right: 0;
  }

  .sustain-header {
    margin-left: 0;
  }

  .about-sustain {
    margin-right: 5rem;
  }

  .products .product3 {
    margin-right: 0;
    margin-bottom: 0;
  }

  .header .nav-links {
    margin-right: 0;
  }

  .about-header {
    margin-left: 0;
  }
}

@media (max-width: 769px) {
  .statics .meats img {
    height: 400px;
}
  .footer h1 {
    font-size: 133px;
  }
  .footer a {
    font-size: 12px;
  }
  .footer span {
    font-size: 12px;
  }
  .footer p {
    font-size: 12px;
  }

  .company-value-border {
    border: none !important;
    border-left: 2px solid #000000 !important;
  }

  .about {
    margin: 2rem 0;
  }

  .statistics p {
    font-size: 140px;
  }

  .accordion-header p {
    margin-left: 1rem;
    font-size: 20px;
  }

  .accordion-collapse {
    margin-top: 50px;
    margin-right: 0;
    margin-left: 1rem;
  }

  .mobile-socials a {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
  }

  // .langs-mobile {
  //   margin-top: 2rem;
  //   margin-bottom: 3rem;
  // }

  .header .menu-btn:checked ~ .menu {
    height: 100vh;
  }

  .header {
    // height: 100vh !important;

    li a {
      margin: 10px 0;
      font-size: 30px;
      border-bottom: 2px solid black;
      padding: 15px 0;
    }
  }

  .why-choose-us {
    margin-right: 0;
  }

  .marquee-container {
    width: 100%;
  }

  .footer .page-links {
    margin-left: 0;
  }

  .product-text {
    margin-left: 0;
  }

  .product-text-left {
    margin-right: 0;
  }

  .about-header {
    margin-left: 0;
  }

  .container-fluid {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (max-width: 576px) {
  .marquee-container {
    // width: 105% !important;
  }

  h1 {
    font-size: 36px;
  }

  .footer .address {
    margin-right: 0;
  }

  .footer .contact-footer p {
    font-size: 28px;
  }

  .about-header {
    margin-left: 0;
    color: #131313;
    font-weight: 500;
    font-size: 36px;
    line-height: 115%;
  }

  .company-value p {
    font-size: 25px;
  }

  .about-sustain {
    margin-right: 0;
    font-size: 36px;
  }
}

@media (max-width: 426px) {
  .header .nav-links img {
    -o-object-fit: contain;
    object-fit: contain;
    width:22px;
    height: 22px;
    display: none;
}
  .company-info {
    justify-content: end !important;
  }
  .footer-flex {
    align-items: baseline;
  }
  .menu-link {
    flex-direction: column;
  }
  .footer-address {
    flex-direction: column;
  }
  .header {
    padding: 20px 20px;
  }
  .langs-element {
    right: 77px;
    top: 34px;
  }
  .footer {
    .down {
      font-size: 11px;
    }
    h1 {
      line-height: 90px;
      font-size: 64px;
    }
  }

  .accordion-header p {
    margin-left: 1rem;
    font-size: 13px;
  }
  button a {
    font-size: 12px !important;
  }
  .footer p {
    font-size: 12px;
  }
  .footer a {
    font-size: 12px;
    margin: 10px 0;
  }
  .statistics p {
    font-size: 136px;
  }
  .contact {
    margin: 3rem 0 0 0;
  }

  .main {
    margin-top: 12rem;
  }

  .sustain-header {
    margin-top: 4rem;
    font-size: 30px;
  }

  .company-value-border {
    border: none !important;
    border-left: 2px solid #000000 !important;
    padding-right: 5rem;
  }

  .products .product-desc {
    padding-right: 2rem;
  }

  .header .lang-link li {
    margin-top: 40px;
    padding: 0 10px;
  }

  //   .langs-mobile {
  //     margin-top: 3rem;
  //     margin-bottom: 3rem;
  // }
}

@media (max-width: 391px) {
  
  .contact  {
    span {
      font-size: 14px;
      line-height: 18px;
    }
    p {
      font-size: 13px;
    }
  }
  .contact input, .contact textarea {
    outline: none;
    border: 1px solid #131313;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
}
  .choose-us h2 {
    font-weight: 500;
    font-size: 31px;
    line-height: 100%;
    color: #f8f8ef;
}
.rublevski .rublevski-content {
  padding-right: 0;
  margin-top: 30px;
}


  .main-products {
    margin-top: 3rem;
}
  .statics {
    p {
      font-weight: 600;
      font-size: 70px;
      line-height: 70px;
    }
    .statics-div {
      margin-bottom: 30px;
      border-bottom: 1px solid black;
    }
    .static {
      border-bottom: 0;
    }
  }
  .export-1 {
    flex-direction: column-reverse;
  }
  .header .nav-links img {
    width: 20px;
    height: 20px;
  }
  .production {
    h2 {
      font-size: 23px;
    line-height: 31px;
    font-weight: 400;
    }
    p {
      padding-right: 0 !important;
      width: 100%;
      font-size: 14px;
    }
  }
  .header li a {
    margin: 0px 0;
    font-size: 25px;
    border-bottom: 2px solid black;
    padding: 15px 0;
  }
  .section-content {
    flex-direction: column-reverse;
    p {
      width: 100%;
      font-size: 16px;
    }
    a {
      font-weight: 600;
      margin-bottom: 30px;
    }
  }
  .header .menu-icon {
    padding: 34px 20px;
  }
  
  .header {
    padding: 20px 20px;
  }
  .langs-element {
    right: 77px;
    top: 40px;
  }
  .langs-element label span:before {
    width: 16px;
    height: 16px;
    box-shadow: inset 0 0 0 0.37em black !important;
  }
  .langs-element label span {
    font-size: 10px;
  }
  .main-arrow {
    display: none;
  }

  .main-products h2 {
    font-weight: 500;
    font-size: 35px;
  }
  .choose-us {
    background-color: #131313;
    padding: 3rem 20px;
  }
  .catalog {
    margin: 3rem 0 3rem 0;
    color: #131313;
    border-top: none;
    border-bottom: none;
  }
  .catalog img {
    display: none;
  }
  .footer h1 {
    font-size: 53px;
    line-height: 53px;
  }

  

  .footer-address {
    flex-direction: column;
  }

  .footer .copy-writer p {
    font-size: 10px;
    line-height: 10px;
    &:last-child {
      text-align: end;
    }
  }

  .header .lang-link li {
    margin-top: 40px;
    padding: 0 10px;
  }

  .footer .contact-footer p {
    font-size: 26px;
  }

  .products .product-desc {
    padding: 0;
  }
}
